<template>
  <div
    class="bg-white rounded-lg w-[450px] h-full sm:h-[221px] shadow-lg p-[40px]"
  >
    <h1 class="font-[Lato] text-[22px] text-[#133C6B] text-center font-bold">
      {{ title }}
    </h1>
    <p class="font-[Lato] text-[14px] pt-5 text-center leading-[21px]">
      Untuk mendapatkan informasi lebih lanjut Anda dapat menghubungi Tim
      Support di email
      <a
        href="mailto:digital.service@jabarprov.go.id"
        class="text-blue-500 hover:underline decoration-solid"
      >
        digital.service@jabarprov.go.id
      </a>
    </p>
  </div>
</template>
<script>
export default {
  name: "NotFound",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>
