<template>
  <div
    class="
      w-16
      h-16
      border-4 border-[#069550] border-solid
      rounded-full
      animate-spin
      border-t-transparent
    "
  ></div>
</template>
<script>
export default {
  name: "BaseLoading",
};
</script>
