var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('transition',{attrs:{"enter-active-class":"transition ease-out duration-300","enter-from-class":"opacity-0","enter-to-class":"opacity-100","leave-active-class":"transition ease-in duration-200","leave-from-class":"opacity-100","leave-to-class":"opacity-0"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticClass:"fixed inset-0 w-full h-screen flex items-center justify-center bg-semi-75",attrs:{"id":"backdrop"}},[_c('div',{staticClass:"modal flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"},[_c('div',{staticClass:"fixed inset-0 transition-opacity",attrs:{"aria-hidden":"true"}},[_c('div',{staticClass:"absolute inset-0 bg-gray-500 opacity-75",attrs:{"id":"penghalang-1"},on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.cancel.apply(null, arguments)}}})]),_c('span',{staticClass:"hidden sm:inline-block sm:align-middle sm:h-screen",attrs:{"aria-hidden":"true"}},[_vm._v("​")]),_c('div',{staticClass:"inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full min-w-[350px] lg:min-w-[450px]",class:{
          'sm:max-w-sm': _vm.size === 'sm',
          'sm:max-w-lg': _vm.size === 'lg',
          'sm:max-w-xl': _vm.size === 'xl',
          'sm:max-w-2xl': _vm.size === '2xl',
          'sm:max-w-3xl': _vm.size === '3xl',
          'sm:max-w-4xl': _vm.size === '4xl',
          'sm:max-w-5xl': _vm.size === '5xl',
          'sm:max-w-6xl': _vm.size === '6xl',
          'sm:max-w-7xl': _vm.size === '7xl',
          'sm:max-w-full': _vm.size === 'full',
        },attrs:{"role":"dialog","aria-modal":"true","aria-labelledby":"modal-headline"}},[_c('div',{staticClass:"bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4"},[_c('div',{staticClass:"flex justify-center"},[(!_vm.submitError)?_c('p',{staticClass:"text-[#069550] text-[25px] font-[inter] font-[700]"},[_vm._v(" KONFIRMASI ")]):_c('div',[_c('p',{staticClass:"text-[25px] font-[inter] font-[700] ml-3 text-red-500"},[_vm._v(" GAGAL ")])])]),(!_vm.isLoading)?_c('div',{staticClass:"flex justify-center mt-10 mb-10"},[(_vm.submitError)?_c('p',{staticClass:"text-[18px] font-[inter] font-[500] text-center first-letter:uppercase"},[_vm._v(" "+_vm._s(_vm.submitErrorMessage)+" ")]):_c('p',{staticClass:"text-[18px] font-[inter] font-[500] text-center"},[_vm._v(" Apakah Anda yakin sudah mengisi data dengan benar dan lengkap? ")])]):_c('div',{staticClass:"flex items-center justify-center mt-10 mb-10"},[_c('div',{staticClass:"w-16 h-16 border-t-transparent border-4 border-[#069550] border-solid rounded-full animate-spin"})])]),(_vm.submitError)?_c('div',{attrs:{"name":"footer"}},[_c('div',{staticClass:"bg-[#F5F5F5] px-4 py-3 sm:px-6"},[_c('div',{staticClass:"flex justify-end"},[_c('jds-button',{staticClass:"btn-cancel-back",attrs:{"type":"button"},on:{"click":_vm.cancel}},[_vm._v(" Kembali ")])],1)])]):_c('div',{attrs:{"name":"footer"}},[_c('div',{staticClass:"bg-[#F5F5F5] px-4 py-3 sm:px-6"},[_c('div',{staticClass:"flex justify-end"},[_c('jds-button',{staticClass:"btn-cancel",attrs:{"type":"button"},on:{"click":_vm.cancel}},[_vm._v(" Batalkan ")]),_c('jds-button',{staticClass:"btn-submit",attrs:{"type":"button"},on:{"click":_vm.submit}},[_vm._v(" Ya, Sudah Benar ")])],1)])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }