import Header from "./Header";
import TableLandingPage from "./TableLandingPage";
import Pagination from "./Pagination";
import Accordion from "./Accordion";
import Footer from "./Footer";

//create global components
export const GlobalComponents = {
  install(Vue) {
    Vue.component("HeaderApp", Header);
    Vue.component("TableApp", TableLandingPage);
    Vue.component("PaginationApp", Pagination);
    Vue.component("AccordionApp", Accordion);
    Vue.component("FooterApp", Footer);
  },
};
