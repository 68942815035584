<template>
  <transition
    enter-active-class="transition ease-out duration-300"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition ease-in duration-200"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      id="backdrop"
      v-show="isOpen"
      class="
        fixed
        inset-0
        w-full
        h-screen
        flex
        items-center
        justify-center
        bg-semi-75
      "
    >
      <div
        class="
          modal
          flex
          items-center
          justify-center
          min-h-screen
          pt-4
          px-4
          pb-20
          text-center
          sm:block sm:p-0
        "
      >
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div
            id="penghalang-1"
            class="absolute inset-0 bg-gray-500 opacity-75"
            @click.self="cancel"
          ></div>
        </div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >

        <div
          :class="{
            'sm:max-w-sm': size === 'sm',
            'sm:max-w-lg': size === 'lg',
            'sm:max-w-xl': size === 'xl',
            'sm:max-w-2xl': size === '2xl',
            'sm:max-w-3xl': size === '3xl',
            'sm:max-w-4xl': size === '4xl',
            'sm:max-w-5xl': size === '5xl',
            'sm:max-w-6xl': size === '6xl',
            'sm:max-w-7xl': size === '7xl',
            'sm:max-w-full': size === 'full',
          }"
          class="
            inline-block
            align-bottom
            bg-white
            rounded-lg
            text-left
            overflow-hidden
            shadow-xl
            transform
            transition-all
            sm:my-8 sm:align-middle sm:w-full
            min-w-[350px]
            lg:min-w-[450px]
          "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="flex justify-center">
              <p
                v-if="!submitError"
                class="text-[#069550] text-[25px] font-[inter] font-[700]"
              >
                KONFIRMASI
              </p>
              <div v-else>
                <p
                  class="text-[25px] font-[inter] font-[700] ml-3 text-red-500"
                >
                  GAGAL
                </p>
              </div>
            </div>
            <div v-if="!isLoading" class="flex justify-center mt-10 mb-10">
              <p
                v-if="submitError"
                class="
                  text-[18px]
                  font-[inter] font-[500]
                  text-center
                  first-letter:uppercase
                "
              >
                {{ submitErrorMessage }}
              </p>
              <p v-else class="text-[18px] font-[inter] font-[500] text-center">
                Apakah Anda yakin sudah mengisi data dengan benar dan lengkap?
              </p>
            </div>
            <div v-else class="flex items-center justify-center mt-10 mb-10">
              <div
                class="
                  w-16
                  h-16
                  border-t-transparent border-4 border-[#069550] border-solid
                  rounded-full
                  animate-spin
                "
              ></div>
            </div>
          </div>

          <div v-if="submitError" name="footer">
            <div class="bg-[#F5F5F5] px-4 py-3 sm:px-6">
              <div class="flex justify-end">
                <jds-button
                  type="button"
                  @click="cancel"
                  class="btn-cancel-back"
                >
                  Kembali
                </jds-button>
              </div>
            </div>
          </div>

          <div v-else name="footer">
            <div class="bg-[#F5F5F5] px-4 py-3 sm:px-6">
              <div class="flex justify-end">
                <jds-button type="button" @click="cancel" class="btn-cancel">
                  Batalkan
                </jds-button>
                <jds-button type="button" @click="submit" class="btn-submit">
                  Ya, Sudah Benar
                </jds-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { defineComponent } from "vue";
import { XCircleIcon } from "@vue-hero-icons/outline";
import { EmojiSadIcon } from "@vue-hero-icons/outline";
export default defineComponent({
  name: "ModalApp",
  components: {
    XCircleIcon,
    EmojiSadIcon,
  },
  props: {
    title: String,
    size: {
      type: String,
      default: "sm",
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    cancel: {
      type: Function,
      default: () => {},
    },
    submit: {
      type: Function,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    cancelFromBackDrop: {
      type: Function,
      default: () => {},
    },
    submitError: {
      type: Boolean,
      default: false,
    },
    submitErrorMessage: {
      type: String,
      default: "",
    },
  },
});
</script>

<style lang="scss">
.modal {
  .btn-cancel {
    background-color: transparent;
    border: 1px solid #069550;
    color: #069550;
    font-size: 14px;
  }
  .btn-cancel:hover {
    background-color: transparent;
    border: 1px solid #069550;
  }
  .btn-cancel-back {
    background-color: transparent;
    border: 1px solid #ef4444;
    color: #ef4444;
    font-size: 14px;
    width: 200px;
  }
  .btn-cancel-back:hover {
    background-color: transparent;
    color: #ef4444;
  }
  .btn-submit {
    background-color: #069550;
    color: #fff;
    min-width: 161px;
    min-height: 38px;
    padding: 10px 16px 10px 16px;
    font-size: 14px;
    font-weight: bold;
    margin-left: 20px;
  }
}
</style>