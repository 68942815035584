import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    tableList: [],
    slug: "",
    page: 1,
    pageSize: 5,
    total: null,
    jobDescription: {},
    error: false,
    loading: false,
    personalInfo:{},
    personalInfoId: "",
  },
  getters: {
    getTableList: (state) => {
      return state.tableList;
    },
    theId: (state) => {
      return state.jobId;
    },
    getPage: (state) => {
      return state.page;
    },
    getPageSize: (state) => {
      return state.pageSize;
    },
    getTotal: (state) => {
      return state.total;
    },
    getJobDescription: (state) => {
      return state.jobDescription;
    },
    getSlug: (state) => {
      return state.slug;
    },
    getError: (state) => {
      return state.error;
    },
    getLoading: (state) => {
      return state.loading;
    },
    getPersonalInfo: (state) => {
      return state.personalInfo;
    },
    getPersonalInfoId: (state) => {
      return state.personalInfoId;
    }

  },
  actions: {
    getTableList: async ({ commit, state }) => {
      const page = state.page;
      const baseUrl = process.env.VUE_APP_BASE_URL;
      const url = `${baseUrl}/vacancies?page=${page}`;
      commit("setLoading", true);
      await axios
        .get(url)
        .then((res) => {
          commit("setTableList", res.data.data);
          commit("setTotal", res.data.total);
          commit("setPage", res.data.current_page);
          commit("setPageSize", res.data.per_page);
        })
        .catch(() => {
          commit("setError", true);
        })
        .finally(() => {
          commit("setLoading", false);
        });
    },
    getJobsDetails: async ({ commit, state }) => {
      const baseUrl = process.env.VUE_APP_BASE_URL;
      const slug = state.slug;
      const url = `${baseUrl}/vacancies/${slug}`;
      commit("setLoading", true);
      await axios
        .get(url)
        .then((res) => {
          commit("setJobDescription", res.data);
          commit("setJobId", res.data.id);
        })
        .catch(() => {
          commit("setError", true);
        })
        .finally(() => {
          commit("setLoading", false);
        });
    },
    getPersonalInformation: async ({ commit, state }) => {
      const baseUrl = process.env.VUE_APP_BASE_URL;
      const id = state.personalInfoId;
      const url = `${baseUrl}/applications/${id}`;
      commit("setLoading", true);
      await axios
        .get(url)
        .then((res) => {
          commit("setPersonalInfo", res.data);
        })
        .catch(() => {
          commit("setError", true);
        })
        .finally(() => {
          commit("setLoading", false);
        });
    }
  },
  mutations: {
    setJobId: (state, id) => {
      state.jobId = id;
    },
    setSlug: (state, slug) => {
      state.slug = slug;
    },
    setTableList: (state, data) => {
      state.tableList = data;
    },
    setTotal: (state, total) => {
      state.total = total;
    },
    setPage: (state, page) => {
      state.page = page;
    },
    setPageSize: (state, pageSize) => {
      state.pageSize = pageSize;
    },
    setJobDescription: (state, jobDescription) => {
      state.jobDescription = jobDescription;
    },
    setError: (state, error) => {
      state.error = error;
    },
    setLoading: (state, loading) => {
      state.loading = loading;
    },
    setPersonalInfo: (state, personalInfo) => {
      state.personalInfo = personalInfo;
    },
    setPersonalInfoId: (state, personalInfoId) => {
      state.personalInfoId = personalInfoId;
    }
  },
  modules: {},
});
