<template>
  <div class="landing-page">
    <div
      class="
        bg-gray-100
        transition-all
        w-full
        duration-300
        min-h-[calc(100vh-310px)]
      "
    >
      <div v-if="isLoading">
        <div class="h-[calc(100vh-310px)] flex justify-center items-center">
          <BaseLoading />
        </div>
      </div>
      <div
        v-else-if="tableList.length > 0"
        class="container mx-auto p-4 flex justify-center"
      >
        <div>
          <h1
            class="
              text-[32px]
              header-job
              font-semibold
              mb-5
              mt-5
              text-[#022B55]
            "
          >
            Posisi Tersedia
          </h1>
          <table-app :table-data="tableList" />
          <div class="mt-5">
            <pagination-app />
          </div>
        </div>
      </div>

      <div
        v-else
        class="
          container
          mx-auto
          flex
          justify-center
          items-center
          px-[30px]
          sm:px-0
          h-[calc(100vh-310px)]
        "
      >
        <NotFound title="Mohon maaf, saat ini belum ada lowongan tersedia." />
      </div>
    </div>
  </div>
</template>

<script>
import NotFound from "@/components/NotFound";
import BaseLoading from "@/components/Loading";

export default {
  name: "IndexPage",
  components: {
    NotFound,
    BaseLoading,
  },
  computed: {
    tableList() {
      return this.$store.getters.getTableList;
    },
    isLoading() {
      return this.$store.getters.getLoading;
    },
  },
  mounted() {
    this.$store.dispatch("getTableList");
  },
};
</script>

<style lang="scss">
.landing-page {
  .header-job {
    font-family: "Lora", serif;
  }
}
</style>
