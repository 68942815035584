import Vue from "vue";
import VueRouter from "vue-router";
import LandingPage from "../views/LandingPage";
import JobDetails from "../views/JobDetails";
import ApplyJobPage from "../views/ApplyJobPage";
import EmailReminderPage from "../views/EmailReminderPage";
import NotFoundPage from "../views/NotFoundPage";
import PersonalInfo from "../views/PersonalInfo";
import OnReviewPage from "../views/OnReview";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "landing-page",
    component: LandingPage,
  },
  {
    path: "/vacancy/:slug",
    name: "job-details",

    component: JobDetails,
    props: true,
  },
  {
    path: "/apply-job/:slug",
    name: "apply-job",

    component: ApplyJobPage,
    props: true,
  },
  {
    path: "/check-your-email/:slug",
    name: "check-your-email",

    component: EmailReminderPage,
    props: true,
  },
  {
    path: "/personal-info/:id",
    name: "personal-info",

    component: PersonalInfo,
    props: true,
  },
  {
    path: "/on-review/:id",
    name: "on-review",

    component: OnReviewPage,
    props: true,
  },
  {
    path: "*",
    name: "NotFoundPage",

    component: NotFoundPage,
    props: true,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
