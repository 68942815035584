<template>
  <div
    class="
      bg-gray-100
      transition-all
      w-full
      duration-300
      min-h-screen
      personal-info-page
    "
  >
    <div class="mx-auto">
      <div class="container mx-auto">
        <div class="flex justify-center">
          <div class="w-3/4 mt-6">
            <div
              v-if="setLoading"
              class="h-[calc(100vh-310px)] flex justify-center items-center"
            >
              <div
                style="border-top-color: transparent"
                class="
                  w-16
                  h-16
                  border-4 border-[#069550] border-solid
                  rounded-full
                  animate-spin
                "
              ></div>
            </div>
            <div
              v-else-if="
                !setLoading &&
                !checkedError &&
                Object.values(personalInfo).length > 0
              "
              class="
                bg-white
                p-3
                shadow-lg
                rounded-lg
                lg:px-20
                px-5
                md:px-10
                mt-6
                mb-10
              "
            >
              <div class="pt-8">
                <h2 class="font-[Lora] font-[700] text-[28px] mb-3">
                  {{ personalInfo.role_name }}
                </h2>
                <h1 class="font-[Lato] font-[700] text-[22px] text-[#022B55]">
                  Data Diri
                </h1>
              </div>
              <div
                class="
                  container
                  p-4
                  mt-6
                  border
                  bg-[#FFF9E1]
                  border-[#FF9500]
                  w-full
                  rounded-lg
                "
              >
                <div class="flex items-center">
                  <ExclamationIcon
                    width="20"
                    height="20"
                    class="text-[#FF9500] mr-3 hidden md:block"
                  />
                  <p
                    class="font-[Inter] text-[12px] align-center text-[#232323]"
                  >
                    <span class="font-extrabold mr-1">Perhatian.</span>Pastikan
                    Anda mengisikan data dengan benar.
                  </p>
                </div>
              </div>
              <div class="container mx-auto form">
                <div class="grid grid-cols-12 gap-1 mt-10">
                  <div class="col-span-12 md:col-span-3">
                    <label for="fullName" class="label-required">
                      Nama Lengkap
                    </label>
                  </div>
                  <div class="col-span-12 lg:col-span-9">
                    <input
                      id="fullName"
                      type="text"
                      disabled
                      v-model="personalInfo.full_name"
                    />
                    <small v-if="errMsg.fullName !== ''" class="text-red-500">{{
                      errMsg.fullName
                    }}</small>
                    <small v-else>{{ errMsg.fullName }}</small>
                  </div>
                </div>

                <div class="grid grid-cols-12 gap-1 mt-10">
                  <div class="col-span-12 md:col-span-3">
                    <label for="gender" class="label-required">
                      Jenis Kelamin
                    </label>
                  </div>
                  <div class="col-span-12 lg:col-span-9">
                    <div class="select_box">
                      <select id="gender" v-model="gender">
                        <option value="M">Laki-laki</option>
                        <option value="F">Perempuan</option>
                      </select>
                    </div>
                    <small v-if="errMsg.gender !== ''" class="text-red-500">{{
                      errMsg.gender
                    }}</small>
                    <small v-else>{{ errMsg.gender }}</small>
                  </div>
                </div>

                <div class="grid grid-cols-12 gap-1 mt-10">
                  <div class="col-span-12 md:col-span-3">
                    <label for="address" class="label-required">
                      Alamat Domisili
                    </label>
                  </div>
                  <div class="col-span-12 lg:col-span-9">
                    <textarea
                      id="address"
                      class="h-[150px]"
                      type="text"
                      rows="4"
                      placeholder="Masukkan alamat domisili Anda"
                      v-model.trim="address"
                      @input="onChangeAddress"
                      @keypress="isLetter"
                    />
                    <small v-if="errMsg.address !== ''" class="text-red-500">{{
                      errMsg.address
                    }}</small>
                    <small v-else>{{ errMsg.address }}</small>
                  </div>
                </div>

                <h2 class="my-10 sub-title">Riwayat Pendidikan</h2>

                <div class="grid grid-cols-12 gap-1 mt-10">
                  <div class="col-span-12 md:col-span-3">
                    <label for="lastEducation" class="label-required">
                      Pendidikan Terakhir
                    </label>
                  </div>
                  <div class="col-span-12 lg:col-span-9">
                    <div class="select_box">
                      <select id="lastEducation" v-model="lastEducation">
                        <option value="S1">S1</option>
                        <option value="S2">S2</option>
                        <option value="S3">S3</option>
                      </select>
                    </div>
                    <small
                      v-if="errMsg.lastEducation !== ''"
                      class="text-red-500"
                      >{{ errMsg.lastEducation }}</small
                    >
                    <small v-else>{{ errMsg.lastEducation }}</small>
                  </div>
                </div>

                <div class="grid grid-cols-12 gap-1 mt-10">
                  <div class="col-span-12 md:col-span-3">
                    <label for="university" class="label-required">
                      Nama Universitas/
                      <br />
                      Institusi
                    </label>
                  </div>
                  <div class="col-span-12 lg:col-span-9">
                    <input
                      id="university"
                      type="text"
                      placeholder="Masukkan nama Universitas atau Institusi Anda"
                      v-model.trim="university"
                      @input="onChangeUniversity"
                      @keypress="isLetter"
                    />
                    <small
                      v-if="errMsg.university !== ''"
                      class="text-red-500"
                      >{{ errMsg.university }}</small
                    >
                    <small v-else>{{ errMsg.university }}</small>
                  </div>
                </div>

                <div class="grid grid-cols-12 gap-1 mt-10">
                  <div class="col-span-12 md:col-span-3">
                    <label for="program" class="label-required">
                      Jurusan/Program Studi
                    </label>
                  </div>
                  <div class="col-span-12 lg:col-span-9">
                    <input
                      id="program"
                      type="text"
                      placeholder="Masukkan Jurusan atau Program Studi Anda"
                      v-model.trim="program"
                      @input="onChangeProgram"
                      @keypress="isLetter"
                    />
                    <small v-if="errMsg.program !== ''" class="text-red-500">{{
                      errMsg.program
                    }}</small>
                    <small v-else>{{ errMsg.program }}</small>
                  </div>
                </div>

                <h2 class="mt-10 sub-title">Riwayat Pekerjaan</h2>

                <div class="grid grid-cols-12 gap-1 mt-10">
                  <div class="col-span-12 md:col-span-3">
                    <label for="experience" class="label-required">
                      Pengalaman Kerja
                    </label>
                  </div>
                  <div class="col-span-12 lg:col-span-9">
                    <div class="textarea-placeholder">
                      <textarea
                        id="experience"
                        class="h-[150px]"
                        type="text"
                        rows="4"
                        placeholder="Masukan pengalaman kerja Anda sesuai format di bawah"
                        v-model.trim="experience"
                        @input="onChangeExperience"
                        @keypress="isLetter"
                      ></textarea>
                      <p class="text-[12px] font-[Lato] text-[#757575]">
                        Contoh format penulisan jika memiliki lebih dari satu
                        pengalaman kerja:
                        <br />
                        PT. Sejahtera Sekali, Backend Engineer, 2016-2019
                        <br />
                        PT. Makmur Jaya, QA Engineer, 2019-2020
                      </p>
                      <small
                        v-if="errMsg.experience !== ''"
                        class="text-red-500"
                        >{{ errMsg.experience }}</small
                      >
                      <small v-else>{{ errMsg.experience }}</small>
                    </div>
                  </div>
                </div>

                <h2 class="mt-10 sub-title">Syarat Dokumen Administrasi</h2>
                <div
                  class="
                    container
                    p-4
                    mt-6
                    border
                    bg-[#FFF9E1]
                    border-[#FF9500]
                    w-full
                    rounded-lg
                  "
                >
                  <div class="flex items-center">
                    <ExclamationIcon
                      width="20"
                      height="20"
                      class="text-[#FF9500] mr-3 hidden md:block"
                    />
                    <p
                      class="
                        font-[Inter]
                        text-[12px]
                        align-center
                        text-[#232323]
                      "
                    >
                      <span class="font-extrabold mr-1">Perhatian.</span
                      >Pastikan Anda mengisikan data dengan benar.
                      Dokumen-dokumen berikut akan kami periksa saat tahapan
                      wawancara.
                    </p>
                  </div>
                </div>

                <div class="grid grid-cols-12 gap-1 mt-10">
                  <div class="col-span-12 md:col-span-3">
                    <label for="ktp" class="label-required">
                      Memiliki KTP
                    </label>
                  </div>
                  <div class="col-span-12 lg:col-span-9">
                    <div class="select_box">
                      <select id="ktp" v-model="ktp">
                        <option :value="false">Tidak</option>
                        <option :value="true">Ya</option>
                      </select>
                    </div>
                    <small v-if="errMsg.ktp !== ''" class="text-red-500">{{
                      errMsg.ktp
                    }}</small>
                    <small v-else>{{ errMsg.ktp }}</small>
                  </div>
                </div>

                <div class="grid grid-cols-12 gap-1 mt-10">
                  <div class="col-span-12 md:col-span-3">
                    <label for="npwp" class="label-required">
                      Memiliki NPWP
                    </label>
                  </div>
                  <div class="col-span-12 lg:col-span-9">
                    <div class="select_box">
                      <select id="npwp" v-model="npwp">
                        <option :value="false">Tidak</option>
                        <option :value="true">Ya</option>
                      </select>
                    </div>
                    <small v-if="errMsg.npwp !== ''" class="text-red-500">{{
                      errMsg.npwp
                    }}</small>
                    <small v-else>{{ errMsg.npwp }}</small>
                  </div>
                </div>

                <div class="grid grid-cols-12 gap-1 mt-10">
                  <div class="col-span-12 md:col-span-3">
                    <label for="ijazah" class="label-required">
                      Memiliki Ijazah
                    </label>
                  </div>
                  <div class="col-span-12 lg:col-span-9">
                    <div class="select_box">
                      <select id="ijazah" v-model="ijazah">
                        <option :value="false">Tidak</option>
                        <option :value="true">Ya</option>
                      </select>
                    </div>
                    <small v-if="errMsg.ijazah !== ''" class="text-red-500">{{
                      errMsg.ijazah
                    }}</small>
                    <small v-else>{{ errMsg.ijazah }}</small>
                  </div>
                </div>

                <div class="grid grid-cols-12 gap-1 mt-10">
                  <div class="col-span-12 md:col-span-3">
                    <label for="transkrip" class="label-required">
                      Memiliki Transkrip Nilai
                    </label>
                  </div>
                  <div class="col-span-12 lg:col-span-9">
                    <div class="select_box">
                      <select id="transkrip" v-model="transkrip">
                        <option :value="false">Tidak</option>
                        <option :value="true">Ya</option>
                      </select>
                    </div>
                    <small
                      v-if="errMsg.transkrip !== ''"
                      class="text-red-500"
                      >{{ errMsg.transkrip }}</small
                    >
                    <small v-else>{{ errMsg.transkrip }}</small>
                  </div>
                </div>

                <div>
                  <div class="mt-20 mb-2 flex justify-center">
                    <jds-button class="button-submit" @click="openModal">
                      Kirim Data Diri Anda
                    </jds-button>
                  </div>
                  <ModalApp
                    :isOpen="isOpen"
                    :cancel="handleCancel"
                    :submit="onSubmit"
                    :isLoading="isLoading"
                    :submitError="errStatus.payload"
                    :submitErrorMessage="errMsg.payload"
                  />
                  <div class="mt-2 mb-20 flex justify-center">
                    <small v-if="!handleErrorModalButton" class="text-red-500">
                      {{ errMsg.submit }}
                    </small>

                    <small v-else> </small>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-else
              class="h-[calc(100vh-310px)] flex justify-center items-center"
            >
              <NotFound
                title="Mohon maaf, lowongan yang anda cari tidak ditemukan"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { ExclamationIcon } from "@vue-hero-icons/solid";
import { ChevronLeftIcon } from "@vue-hero-icons/outline";
import ModalApp from "../../components/Modal";
import NotFound from "@/components/NotFound";
import axios from "axios";
const regex = /[@#!$%^&*()_+~`="|}{\[\]\\:;?></-]/g;
export default {
  name: "personal-info",
  components: {
    ExclamationIcon,
    ChevronLeftIcon,
    ModalApp,
    NotFound,
  },

  data: () => {
    return {
      fullName: "",
      isOpen: false,
      address: "",
      university: "",
      program: "",
      experience: "",
      isLoading: false,
      gender: "M",
      lastEducation: "S1",
      ktp: false,
      npwp: false,
      transkrip: false,
      ijazah: false,

      errMsg: {
        fullName: "",
        address: "",
        university: "",
        program: "",
        experience: "",
        payload: "",
        submit: "",
        gender: "",
        lastEducation: "",
        ktp: "",
        npwp: "",
        transkrip: "",
        ijazah: "",
      },
      errStatus: {
        payload: false,
      },
    };
  },
  created() {
    this.$store.commit("setPersonalInfoId", this.$route.params.id);

    this.$store.dispatch("getPersonalInformation");
  },
  computed: {
    handleErrorModalButton() {
      return (
        this.errMsg.address === "" &&
        this.errMsg.university === "" &&
        this.errMsg.program === "" &&
        this.errMsg.experience === ""
      );
    },

    personalInfo() {
      return this.$store.getters.getPersonalInfo;
    },
    setLoading() {
      return this.$store.getters.getLoading;
    },
    checkedError() {
      return this.$store.getters.getError;
    },
  },
  methods: {
    handleCancel() {
      this.isOpen = false;
    },
    isLetter(e) {
      if (e.target.value.length < 1 && e.key === " ") {
        e.preventDefault();
      }
    },
    onChangeAddress() {
      if (this.address.length > 0) {
        let regexAddress = /[@#!$%^&*()_+~`="|}{\[\]\\:;?><']/g;
        if (regexAddress.test(this.address)) {
          this.address = this.address.replace(regexAddress, "");
          this.errMsg.address = "Alamat tidak boleh mengandung karakter khusus";
        } else {
          this.errMsg.address = "";
        }
      } else {
        this.errMsg.address = "Alamat tidak boleh kosong";
      }
    },
    onChangeUniversity() {
      if (this.university.length > 0) {
        if (regex.test(this.university)) {
          this.university = this.university.replace(regex, "");
          this.errMsg.university =
            "Universitas tidak boleh mengandung karakter khusus";
        } else {
          this.errMsg.university = "";
        }
      } else {
        this.errMsg.university = "Nama universitas tidak boleh kosong";
      }
    },
    onChangeProgram() {
      if (this.program.length > 0) {
        if (regex.test(this.program)) {
          this.program = this.program.replace(regex, "");
          this.errMsg.program =
            "Program studi tidak boleh mengandung karakter khusus";
        } else {
          this.errMsg.program = "";
        }
      } else {
        this.errMsg.program = "Program studi tidak boleh kosong";
      }
    },
    onChangeExperience() {
      const regexExp = /[@#!$%^&*()_+~`="|}{\[\]\\:;?></]/g;
      if (this.experience.length > 0) {
        if (regexExp.test(this.experience)) {
          this.experience = this.experience.replace(regexExp, "");
          this.errMsg.experience =
            "Pengalaman tidak boleh mengandung karakter khusus";
        } else {
          this.errMsg.experience = "";
        }
      } else {
        this.errMsg.experience = "Pengalaman kerja tidak boleh kosong";
      }
    },
    openModal() {
      this.errStatus.payload = false;
      this.errMsg.payload = "";
      if (this.gender === "") {
        this.errMsg.gender = "Jenis kelamin tidak boleh kosong";
      } else {
        this.errMsg.gender = "";
      }
      if (this.lastEducation === "") {
        this.errMsg.lastEducation = "Pendidikan terakhir tidak boleh kosong";
      } else {
        this.errMsg.lastEducation = "";
      }
      if (this.ktp === "") {
        this.errMsg.ktp = "KTP tidak boleh kosong";
      } else {
        this.errMsg.ktp = "";
      }

      if (this.npwp === "") {
        this.errMsg.npwp = "NPWP tidak boleh kosong";
      } else {
        this.errMsg.npwp = "";
      }

      if (this.transkrip === "") {
        this.errMsg.transkrip = "Transkrip tidak boleh kosong";
      } else {
        this.errMsg.transkrip = "";
      }

      if (this.ijazah === "") {
        this.errMsg.ijazah = "Ijazah tidak boleh kosong";
      } else {
        this.errMsg.ijazah = "";
      }

      if (this.address === "") {
        this.errMsg.address = "Alamat tidak boleh kosong";
      } else {
        this.errMsg.address = "";
      }
      if (this.university === "") {
        this.errMsg.university = "Nama universitas tidak boleh kosong";
      } else {
        this.errMsg.university = "";
      }
      if (this.program === "") {
        this.errMsg.program = "Program studi tidak boleh kosong";
      } else {
        this.errMsg.program = "";
      }
      if (this.experience === "") {
        this.errMsg.experience = "Pengalaman kerja  tidak boleh kosong";
      } else {
        this.errMsg.experience = "";
      }

      if (
        this.errMsg.address === "" &&
        this.errMsg.university === "" &&
        this.errMsg.program === "" &&
        this.errMsg.experience === "" &&
        this.errMsg.gender === "" &&
        this.errMsg.lastEducation === "" &&
        this.errMsg.ktp === "" &&
        this.errMsg.npwp === "" &&
        this.errMsg.transkrip === "" &&
        this.errMsg.ijazah === ""
      ) {
        this.isOpen = true;
      } else {
        this.errMsg.submit = "Mohon lengkapi data diri Anda";
      }
    },
    onSubmit() {
      this.isLoading = true;
      const baseUrl = process.env.VUE_APP_BASE_URL;
      const payload = {
        address: this.address,
        university: this.university,
        major: this.program,
        work_experience: this.experience,
        gender: this.gender,
        education: this.lastEducation,
        has_ktp: this.ktp,
        has_npwp: this.npwp,
        has_ijazah: this.ijazah,
        has_transkrip: this.transkrip,
      };

      axios
        .put(`${baseUrl}/applications/${this.$route.params.id}`, payload)
        .then((res) => {
          this.isOpen = true;
          this.errStatus.payload = false;
          this.errMsg.payload = "";
          setTimeout(() => {
            this.$router.push({
              name: "on-review",
              params: {
                id: this.$route.params.id,
              },
            });
          }, 1000);
        })
        .catch((err) => {
          this.isLoading = false;
          this.errStatus.payload = true;
          this.errMsg.payload = err.response.data.message;
        });
    },
  },
};
</script>

  <style lang="scss">
.personal-info-page {
  .button-back {
    background-color: transparent;
    border: 1px solid #069550;
    color: #069550;
    font-size: 14px;
  }

  .button-back:hover {
    background-color: transparent;
    border: 1px solid #069550;
    color: #069550;
    font-size: 14px;
  }

  .button-submit {
    background-color: #069550;
    color: #fff;
    min-width: 161px;
    min-height: 38px;
    padding: 10px 16px 10px 16px;
    font-size: 14px;
    font-weight: bold;
  }

  * {
    font-family: "Lato", serif;
  }

  *:focus {
    outline: none;
  }

  *::placeholder {
    font-size: 14px;
  }

  label,
  .label-required {
    font-size: 16px;
    font-weight: 600;
    color: #232323;
  }

  .label-required::after {
    content: "*";
    color: #cb2e2e;
    margin-left: 3px;
  }

  .label-input-file {
    background-color: #069550;
    margin-right: 5px;
    padding: 10px 16px 10px 16px;
    border: none;
    border-radius: 8px;
    font-weight: bold;
    color: white;
    min-width: 132px;
    font-size: 14px;
    display: inline-block;
  }

  input[type="text"],
  input[type="email"],
  input[type="number"] {
    background-color: #fdfdfd;
    height: 38px;
    border: 1px solid #9e9e9e;
    border-radius: 8px;
    width: 100%;
    padding: 8px;
    color: #232323;
  }

  .sub-title {
    font-size: 22px;
    font-weight: bold;
    color: #022b55;
  }

  textarea {
    background-color: #fafafa;
    border: 1px solid #9e9e9e;
    border-radius: 8px;
    width: 100%;
    padding: 7px;
  }

  .select_box {
    border: 1px solid #9e9e9e;
    border-radius: 8px;
    width: 174px;
    select {
      background: url("../../assets/Vector.svg") no-repeat right;
      margin-left: 10px;
      width: 100%;
      padding: 5px;
      font-size: 16px;
      line-height: 1;
      border: 0;
      border-radius: 5px;
      height: 34px;
      -webkit-appearance: none;
      background-position-x: 134px;
    }
    @media screen and (max-width: 440px) {
      width: 100%;
      select {
        background-position-x: 95%;
      }
    }
  }
}
</style>
  