<template name="component-name">
  <div>
    <div class="flex justify-center mt-3 mb-5">
      <div class="flex items-center space-x-1">
        <a
          href="#"
          class="
            w-8
            h-8
            items-center
            flex
            justify-center
            text-gray-500
            bg-gray-200
            hover:bg-[#069550]
            rounded-full
            hover:text-white
          "
          @click="goToThePreviousPage"
        >
          <ChevronLeftIcon />
        </a>
        <div v-for="(data, index) in pages" :key="index">
          <a
            href="#"
            class="
              px-4
              py-2
              w-8
              h-8
              rounded-full
              items-center
              flex
              justify-center
              hover:bg-green-500
            "
            :class="
              data === page
                ? 'bg-[#069550] text-white'
                : 'bg-gray-200 text-gray-700'
            "
            @click="handleNumberClick(data)"
          >
            {{ data }}
          </a>
        </div>

        <a
          href="#"
          class="
            w-8
            h-8
            items-center
            flex
            justify-center
            text-gray-500
            bg-gray-200
            hover:bg-[#069550]
            rounded-full
            hover:text-white
          "
          @click="goToTheNextPage"
        >
          <ChevronRightIcon />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { ArrowCircleLeftIcon } from "@vue-hero-icons/outline";
import { ArrowCircleRightIcon } from "@vue-hero-icons/outline";
import { ChevronRightIcon } from "@vue-hero-icons/outline";
import { ChevronLeftIcon } from "@vue-hero-icons/outline";

export default {
  name: "PaginationApp",
  components: {
    ArrowCircleLeftIcon,
    ArrowCircleRightIcon,
    ChevronRightIcon,
    ChevronLeftIcon,
  },
  data() {
    return {
      dataLength: 0,
      paginationNumbers: [],
    };
  },
  computed: {
    page() {
      return this.$store.getters.getPage;
    },
    pageSize() {
      return this.$store.getters.getPageSize;
    },
    total() {
      return this.$store.getters.getTotal;
    },

    pages() {
      const numShown = 3;
      let first = this.page - Math.floor(numShown / 2);
      first = Math.max(first, 1);
      // first = Math.min(first, this.total - numShown + 1);
      if (first + numShown > this.paginationNumbers.length) {
        first = this.paginationNumbers.length - numShown + 1;
      }
      let last = first + numShown - 1;
      // last = Math.min(last, this.total);

      return this.paginationNumbers.slice(first - 1, last);
    },
  },
  mounted() {
    const total = Math.ceil(this.total / this.pageSize);
    this.dataLength = total;

    this.paginationNumbers = Array.from(Array(this.dataLength).keys()).map(
      (x) => x + 1
    );
  },
  methods: {
    goToTheNextPage() {
      if (this.page < this.dataLength) {
        this.$store.commit("setPage", this.page + 1);
        this.$store.dispatch("getTableList");
      } else {
        this.$store.commit("setPage", this.dataLength);
      }
    },
    goToThePreviousPage() {
      if (this.page > 1) {
        this.$store.commit("setPage", this.page - 1);
        this.$store.dispatch("getTableList");
      } else {
        this.$store.commit("setPage", 1);
      }
    },
    handleNumberClick(number) {
      this.$store.commit("setPage", number);
      this.$store.dispatch("getTableList");
    },
  },
};
</script>
