<template>
  <div class="border-2 p-2 rounded mt-2 w-[700px]">
    <div
      class="bg-green-500 text-white p-4 font-bold rounded cursor-pointer"
      @click="isOpen = !isOpen"
    >
      {{ title }}
    </div>
    <div
      class="mt-4 border-2 p-2 rounded"
      :class="isOpen ? 'block' : 'hidden'"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccordionApp",
  props: {
    title: String,
  },
  data: () => {
    return {
      isOpen: false,
    };
  },
};
</script>
