<template name="footer-app">
  <footer>
    <div class="grid grid-cols-12 h-[60px] mt-3 md:mt-0">
      <router-link
        to="/"
        class="
          col-span-6
          md:col-span-2
          flex
          justify-start
          md:justify-end
          items-center
          ml-5
          md:ml-0
        "
      >
        <img src="@/assets/logo-footer.png" alt="footer-logo" />
      </router-link>

      <div
        class="
          col-span-6
          md:col-span-8
          flex
          justify-end
          md:justify-center
          items-center
        "
      >
        <p class="text-[#022B55] text-[12px] font-['inter']">
          Copyright © 2022 Jabar Digital Service. All Right Reserved
        </p>
      </div>
      <div
        class="
          col-span-12
          md:col-span-2
          flex
          justify-start
          md:justify-start
          items-center
          mt-5
          md:mt-0
          ml-5
          md:ml-0
        "
      >
        <a
          href="https://id-id.facebook.com/jabardigitalservice"
          target="_blank"
          class="mr-5 mb-5 md:mb-0"
        >
          <img src="@/assets/fblogo.svg" alt="facebook" class="footer-img" />
        </a>
        <a
          href="https://www.instagram.com/jabardigitalservice/?hl=id"
          target="_blank"
          class="mr-5 mb-5 md:mb-0"
        >
          <img
            src="@/assets/instalogo.svg"
            alt="instagram"
            class="w-[21.41px] h-[21.41px]"
          />
        </a>
        <a
          href="https://twitter.com/jabardigital?s=20&t=OQ3VFQdIgUNQMg1OKz_RYQ"
          target="_blank"
          class="mb-5 md:mb-0"
        >
          <img
            src="@/assets/twitlogo.svg"
            alt="twitter"
            class="w-[21.41px] h-[21.41px]"
          />
        </a>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name : "FooterApp"
}
</script>
