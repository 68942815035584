<template name="component-name">
  <div
    class="
      bg-gray-100
      transition-all
      w-full
      duration-300
      min-h-screen
      form-page
    "
  >
    <div class="mx-auto">
      <div class="container mx-auto">
        <div class="flex justify-center">
          <div class="w-3/4 mt-6">
            <jds-button class="button-back" @click.prevent="goToThePrevPage">
              <div class="flex">
                <ChevronLeftIcon class="w-[14px] h-[14px] mt-[6px] mr-2" />
                Kembali
              </div>
            </jds-button>
            <div
              v-if="setLoading"
              class="h-[calc(100vh-310px)] flex justify-center items-center"
            >
              <div
                style="border-top-color: transparent"
                class="
                  w-16
                  h-16
                  border-4 border-[#069550] border-solid
                  rounded-full
                  animate-spin
                "
              ></div>
            </div>
            <div
              v-else-if="
                !setLoading &&
                !checkedError &&
                Object.values(tableData).length > 0
              "
              class="
                bg-white
                p-3
                shadow-lg
                rounded-lg
                lg:px-20
                px-5
                md:px-10
                mt-6
                mb-10
              "
            >
              <div class="pt-8">
                <h2
                  class="font-[Inter] font-bold text-[22px] mb-3 text-[#757575]"
                >
                  Form Pendaftaran
                </h2>
                <h1 class="text-4xl font-[Lora] font-bold text-[28px]">
                  {{ tableData?.role.name }}
                </h1>
              </div>
              <div
                class="
                  container
                  p-4
                  mt-6
                  border
                  bg-[#FFF9E1]
                  border-[#FF9500]
                  w-full
                  rounded-lg
                "
              >
                <div class="flex items-center">
                  <ExclamationIcon
                    width="20"
                    height="20"
                    class="text-[#FF9500] mr-3"
                  />
                  <p
                    class="font-[Inter] text-[12px] align-center text-[#232323]"
                  >
                    <span class="font-extrabold mr-1">Perhatian.</span>Pastikan
                    nomor telepon dan alamat email bisa dihubungi
                  </p>
                </div>
              </div>
              <div class="container mx-auto form">
                <div class="grid grid-cols-12 gap-1 mt-10">
                  <div class="col-span-12 md:col-span-3">
                    <label for="fullName" class="label-required">
                      Nama Lengkap
                    </label>
                  </div>
                  <div class="col-span-12 lg:col-span-9">
                    <input
                      id="fullName"
                      v-model="fullName"
                      type="text"
                      placeholder="Masukkan nama lengkap anda"
                      minlength="2"
                      maxlength="100"
                      @keypress="isLetter($event)"
                      @input="onChangeFullName($event)"
                    />
                    <small v-if="errMsg.fullName !== ''" class="text-red-500">{{
                      errMsg.fullName
                    }}</small>
                    <small v-else>{{ errMsg.fullName }}</small>
                  </div>
                </div>

                <div class="grid grid-cols-12 gap-1 mt-6">
                  <div class="col-span-12 md:col-span-3">
                    <label for="email" class="label-required"> Email </label>
                  </div>
                  <div class="col-span-12 lg:col-span-9">
                    <input
                      id="email"
                      v-model="email"
                      type="email"
                      placeholder="Masukkan alamat email Anda"
                      @change="validateEmail"
                    />
                    <small class="text-red-500">{{ errMsg.email }}</small>
                  </div>
                </div>

                <div class="grid grid-cols-12 gap-1 mt-6">
                  <div class="col-span-12 md:col-span-3">
                    <label for="phone" class="label-required">
                      Nomor Telepon
                    </label>
                  </div>
                  <div class="col-span-12 lg:col-span-9">
                    <input
                      id="phone"
                      v-model="phone"
                      type="number"
                      placeholder="Masukkan nomor telepon Anda"
                      min="0"
                      class="bg-red-500"
                      @input="onChangePhoneNumber($event)"
                      @keypress="isNumber($event)"
                    />
                    <small class="text-red-500">{{ errMsg.phone }}</small>
                  </div>
                </div>

                <div class="grid grid-cols-12 gap-1 mt-6">
                  <div class="col-span-12 md:col-span-3">
                    <label for="resume" class="label-required">
                      Resume/CV
                    </label>
                  </div>
                  <div class="col-span-12 lg:col-span-9">
                    <div class="md:flex md:items-center">
                      <label
                        for="input-file"
                        class="label-input-file cursor-pointer"
                      >
                        <input
                          type="file"
                          id="input-file"
                          class="hidden"
                          @change="pickFileResume"
                          ref="resume"
                          accept="application/pdf"
                        />
                        <div class="flex align-items-center">
                          <img
                            src="@/assets/image/cloud-upload.png"
                            width="24"
                            height="24"
                            alt="cloud upload"
                            class="mr-2"
                          />
                          Upload CV
                        </div>
                      </label>
                      <p
                        class="
                          font-[Lato]
                          text-[14px] text-gray-600
                          ml-3
                          mt-3
                          md:mt-0
                        "
                      >
                        Pastikan file berformat PDF dan ukuran file tidak
                        melebihi 5MB
                      </p>
                    </div>

                    <small class="mt-5 font-[Lato]">{{ fileResumeName }}</small>
                    <div>
                      <small class="text-red-500 mt-5">{{
                        errMsg.fileResume
                      }}</small>
                    </div>
                  </div>
                </div>

                <h2 class="my-10 sub-title">Link</h2>

                <div class="grid grid-cols-12 gap-1">
                  <div class="col-span-12 md:col-span-3">
                    <label for="linkedin" class="text-gray-700">
                      Linkedin URL
                    </label>
                  </div>
                  <div class="col-span-12 lg:col-span-9">
                    <input
                      id="linkedin"
                      v-model="linkedin"
                      type="text"
                      placeholder="Masukkan nama alamat url LinkedIn Anda"
                      @change="validateUrlLinkedin"
                    />
                    <small class="text-red-500">{{ errMsg.linkedin }}</small>
                  </div>
                </div>

                <div class="grid grid-cols-12 gap-1 mt-6">
                  <div class="col-span-12 md:col-span-3">
                    <label for="portofolio" class="text-gray-700">
                      Portofolio URL
                    </label>
                  </div>
                  <div class="col-span-12 lg:col-span-9">
                    <input
                      id="portofolio"
                      v-model="portofolio"
                      type="text"
                      placeholder="Masukkan nama alamat url Portofolio Anda"
                      @change="validateUrlPortofolio"
                    />
                    <small class="text-red-500">{{ errMsg.portofolio }}</small>
                  </div>
                </div>

                <h2 class="mt-10 sub-title">Informasi Lainnya</h2>

                <textarea
                  id="additionalInfo"
                  v-model="additionalInfo"
                  class="mt-6 h-[161px]"
                  type="text"
                  rows="4"
                  placeholder="Ceritakan tentang diri Anda di sini..."
                  @input="onChangeAdditionalInfo"
                  maxlength="255"
                />
                <p class="text-[12px] font-[Lato] text-[#757575]">
                  Sisa karakter : {{ additionalInfoLength }} dari 255
                </p>
                <div>
                  <div class="mt-20 mb-2 flex justify-center">
                    <jds-button
                      class="button-submit"
                      @click="openModal"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      Kirim Data Diri Anda
                    </jds-button>
                  </div>
                  <ModalApp
                    :isOpen="isOpen"
                    :cancel="handleCancel"
                    :submit="onSubmit"
                    :isLoading="isLoading"
                    :submitError="errStatus.payload"
                    :submitErrorMessage="errMsg.payload"
                  />

                  <div class="mt-2 mb-20 flex justify-center">
                    <small v-if="!handleErrorModalButton" class="text-red-500">
                      {{ errMsg.submit }}
                    </small>

                    <small v-else> </small>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="h-[calc(100vh-310px)] flex justify-center items-center"
            >
              <NotFound
                title="Mohon maaf, lowongan yang anda cari tidak ditemukan"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ExclamationIcon } from "@vue-hero-icons/solid";
import { ChevronLeftIcon } from "@vue-hero-icons/outline";
import ModalApp from "../../components/Modal";
import NotFound from "@/components/NotFound";
import axios from "axios";
export default {
  name: "ApplyJobPage",
  components: {
    ExclamationIcon,
    ChevronLeftIcon,
    ModalApp,
    NotFound,
  },
  data: () => {
    return {
      jobName: "",
      fullName: "",
      email: "",
      isOpen: false,
      phone: "",
      linkedin: "",
      portofolio: "",
      fileResume: null,
      fileResumeName: "",
      showFileName: false,
      additionalInfo: "",
      additionalInfoLength: 0,
      isLoading: false,

      errMsg: {
        fullName: "",
        email: "",
        phone: "",
        linkedin: "",
        portofolio: "",
        fileResume: "",
        submit: "",
        payload: "",
      },
      errStatus: {
        fullName: false,
        email: false,
        phone: false,
        linkedin: false,
        portofolio: false,
        fileResume: false,
        submit: false,
        payload: false,
      },
    };
  },
  created() {
    this.$store.commit("setSlug", this.$route.params.slug);
    this.$store.dispatch("getJobsDetails");
  },
  computed: {
    tableData() {
      return this.$store.getters.getJobDescription;
    },
    checkedError() {
      return this.$store.getters.getError;
    },
    theId() {
      return this.$store.getters.theId;
    },
    handleErrorModalButton() {
      return (
        this.errMsg.fullName == "" &&
        this.errMsg.email == "" &&
        this.errMsg.phone == "" &&
        this.errMsg.fileResume == "" &&
        this.fileResume != null &&
        this.errMsg.portofolio == "" &&
        this.errMsg.linkedin == ""
      );
    },
    setLoading() {
      return this.$store.getters.getLoading;
    },
  },
  methods: {
    handleCancel(e) {
      e.preventDefault();
      this.isOpen = false;
    },

    isLetter(e) {
      var regex = /^[a-zA-Z0-9 ']+$/;
      if (!regex.test(e.key)) {
        e.preventDefault();
      }
      if (e.target.value.length < 2 && e.key == " ") {
        e.preventDefault();
      }
    },
    onChangeAdditionalInfo() {
      this.additionalInfoLength = this.additionalInfo.length;
    },
    onChangeFullName(e) {
      this.fullName = e.target.value;
      let regex = /[<>()[\]{}]/;
      let regexLink = /http(s)?:\/\/\w+/;
      if (this.fullName.length > 0) {
        if (this.fullName.length < 2) {
          this.errMsg.fullName =
            "Nama minimal 2 karakter dan tidak boleh mengandung spasi";
          this.errStatus.fullName = true;
        } else if (this.fullName.length >= 2) {
          if (regex.test(this.fullName)) {
            this.errMsg.fullName =
              "Nama tidak boleh mengandung karakter <, >, (, ), {, }, [, ]";
            this.errStatus.fullName = true;
          } else if (regexLink.test(this.fullName)) {
            this.errMsg.fullName = "Nama tidak boleh mengandung link";
            this.errStatus.fullName = true;
          } else {
            this.errMsg.fullName = "";
            this.errStatus.fullName = false;
          }
        }
      } else {
        this.errMsg.fullName = "Nama tidak boleh kosong";
        this.errStatus.fullName = true;
      }
    },
    isNumber(evt) {
      const keysAllowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
      const keyPressed = evt.key;

      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault();
      }
    },
    onChangePhoneNumber(e) {
      this.phone = e.target.value;

      if (this.phone.length < 9) {
        let regex = /^(081|082|083|085|087|088|089)[0-9]+$/;
        if (regex.test(this.phone)) {
          this.errMsg.phone =
            "Nomor telepon minimal 9 karakter dan tidak boleh mengandung karakter selain angka";
          this.phone = e.target.value;
          this.errStatus.phone = true;
        } else {
          this.errMsg.phone = "Nomor telepon tidak valid";
          this.phone = this.phone.substring(0, 3);
          this.errStatus.phone = true;
        }
      } else if (this.phone.length > 9) {
        this.errMsg.phone = "";
        this.errStatus.phone = false;
      }
      if (this.phone.length > 13) {
        this.phone = this.phone.substring(0, 13);
      } else if (this.phone.length == 0) {
        this.errMsg.phone = "Nomor telepon tidak boleh kosong";
        this.errStatus.phone = true;
      }
    },

    validateUrlLinkedin() {
      if (
        this.linkedin.length > 0 &&
        !this.linkedin.includes("https://www.linkedin.com/in/")
      ) {
        this.errMsg.linkedin = "Link linkedin tidak valid";
        this.errStatus.linkedin = true;
        return false;
      } else {
        this.errMsg.linkedin = "";
        this.errStatus.linkedin = false;
        return true;
      }
    },
    pickFileResume() {
      this.$refs.resume.click();
      let input = this.$refs.resume;
      let file = input.files;

      if (
        file &&
        file[0] &&
        file[0].size < 5000000 &&
        file[0].type === "application/pdf"
      ) {
        this.fileResume = input.files[0];
        this.fileResumeName = input.files[0].name;
        this.errMsg.fileResume = "";
        this.errStatus.fileResume = false;
      } else {
        this.fileResume = null;
        this.fileResumeName = "";
        this.errMsg.fileResume =
          "File resume harus berformat pdf dan ukuran maksimal 5MB";
        this.errStatus.fileResume = true;
      }
    },

    validateEmail() {
      var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(this.email)) {
        this.errMsg.email = "";
        this.errStatus.email = false;
        return true;
      } else {
        this.errMsg.email = "Email tidak valid";
        this.errStatus.email = true;
        return false;
      }
    },
    validateUrlPortofolio() {
      if (
        this.portofolio.length > 0 &&
        !this.portofolio.includes("https://") &&
        !this.portofolio.includes("http://")
      ) {
        this.errMsg.portofolio = "Link portofolio tidak valid";
        this.errStatus.portofolio = true;
        return false;
      } else {
        this.errMsg.portofolio = "";
        this.errStatus.portofolio = false;
        return true;
      }
    },

    validateThreeFirstDigitPhoneNumber() {
      let regex = /^(081|082|083|085|087|088|089)[0-9]+$/;
      if (regex.test(this.phone)) {
        this.errMsg.phone = "";
        this.errStatus.phone = false;
        return true;
      } else {
        this.errMsg.phone = "Nomor telepon tidak valid";
        this.errStatus.phone = true;
        return false;
      }
    },
    openModal() {
      this.errStatus.payload = false;
      this.errMsg.payload = "";
      if (this.fullName == "") {
        this.errMsg.fullName = "Nama tidak boleh kosong";
      } else if (this.errStatus.fullName) {
        this.errMsg.fullName = "Nama tidak valid";
      } else if (this.fullName.substring(0, 2).includes(" ")) {
        this.errMsg.fullName = "Nama tidak valid";
      } else {
        this.errMsg.fullName = "";
      }
      if (this.email == "") {
        this.errMsg.email = "Email tidak boleh kosong";
      } else if (this.validateEmail() == false) {
        this.errMsg.email = "Email tidak valid";
      }
      if (this.phone == "") {
        this.errMsg.phone = "Nomor telepon tidak boleh kosong";
      } else if (this.errStatus.phone) {
        this.errMsg.phone = "Nomor telepon tidak valid";
      } else if (this.validateThreeFirstDigitPhoneNumber() == false) {
        this.errMsg.phone = "Nomor telepon tidak valid";
      }
      if (this.fileResume == null) {
        this.errMsg.fileResume = "File resume tidak boleh kosong";
      } else if (this.errStatus.fileResume) {
        this.errMsg.fileResume = "File resume tidak valid";
      }

      if (
        this.errMsg.fullName == "" &&
        this.errMsg.email == "" &&
        this.errMsg.phone == "" &&
        this.errMsg.fileResume == "" &&
        this.fileResume != null &&
        this.errMsg.linkedin == "" &&
        this.errMsg.portofolio == ""
      ) {
        this.errMsg.submit = "";
        this.isOpen = true;
      } else {
        this.errMsg.submit = "Cek kembali data yang anda masukkan";
        this.isOpen = false;
      }
    },

    goToThePrevPage() {
      this.$router.go(-1);
    },
    onSubmit(e) {
      e.preventDefault();
      this.isLoading = true;
      const baseUrl = process.env.VUE_APP_BASE_URL;
      const formData = new FormData();
      formData.append("full_name", this.fullName);
      formData.append("email", this.email);
      formData.append("phone", this.phone);
      formData.append("linkedin_url", this.linkedin);
      formData.append("portofolio_url", this.portofolio);
      formData.append("cv", this.fileResume);
      formData.append("add_info", this.additionalInfo);
      formData.append("vacancy_id", this.tableData.id);

      axios
        .post(`${baseUrl}/applications`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.isOpen = true;
          this.errStatus.payload = false;
          this.errMsg.payload = "";
          setTimeout(() => {
            this.$router.push({
              name: "check-your-email",
              params: {
                slug: this.$route.params.slug,
              },
            });
          }, 1000);
        })
        .catch((err) => {
          this.isLoading = false;
          this.errStatus.payload = true;
          this.errMsg.payload = err.response.data.message;
        });
    },
  },
};
</script>

<style lang="scss">
.form-page {
  .button-back {
    background-color: transparent;
    border: 1px solid #069550;
    color: #069550;
    font-size: 14px;
  }

  .button-back:hover {
    background-color: transparent;
    border: 1px solid #069550;
    color: #069550;
    font-size: 14px;
  }

  .button-submit {
    background-color: #069550;
    color: #fff;
    min-width: 161px;
    min-height: 38px;
    padding: 10px 16px 10px 16px;
    font-size: 14px;
    font-weight: bold;
  }

  * {
    font-family: "Lato", serif;
  }

  *:focus {
    outline: none;
  }

  *::placeholder {
    font-size: 14px;
  }

  label,
  .label-required {
    font-size: 16px;
    font-weight: 600;
    color: #232323;
  }

  .label-required::after {
    content: "*";
    color: #cb2e2e;
    margin-left: 3px;
  }

  .label-input-file {
    background-color: #069550;
    margin-right: 5px;
    padding: 10px 16px 10px 16px;
    border: none;
    border-radius: 8px;
    font-weight: bold;
    color: white;
    min-width: 132px;
    font-size: 14px;
    display: inline-block;
  }

  input[type="text"],
  input[type="email"],
  input[type="number"] {
    background-color: #fdfdfd;
    height: 38px;
    border: 1px solid #9e9e9e;
    border-radius: 8px;
    width: 100%;
    padding: 8px;
    color: #232323;
  }

  .sub-title {
    font-size: 22px;
    font-weight: bold;
    color: #022b55;
  }

  textarea {
    background-color: #fafafa;
    border: 1px solid #9e9e9e;
    border-radius: 8px;
    width: 100%;
    padding: 7px;
  }
}
</style>
