<template>
  <div class="not-found-page">
    <div
      class="
        bg-gray-100
        transition-all
        w-full
        duration-300
        min-h-[calc(100vh-310px)]
      "
    >
      <div
        class="
          container
          mx-auto
          flex
          justify-center
          items-center
          px-[30px]
          sm:px-0
          h-[calc(100vh-310px)]
        "
      >
        <NotFound title="Halaman tidak ditemukan." />
      </div>
    </div>
  </div>
</template>
  
  <script>
import NotFound from "@/components/NotFound";
export default {
  name: "NotFoundPage",
  components: {
    NotFound,
  },
};
</script>