<template >
  <div
    class="
      bg-gray-100
      transition-all
      w-full
      duration-300
      min-h-[calc(100vh-310px)]
    "
  >
    <div class="mx-auto p-4 w-full">
      <div class="container mx-auto pb-4 pt-4 px-14">
            
        <div
          v-show="loading && isLoadingImage"
          class="h-[calc(100vh-310px)] flex justify-center items-center"
        >
          <div
            style="border-top-color: transparent"
            class="
              w-16
              h-16
              border-4 border-[#069550] border-solid
              rounded-full
              animate-spin
            "
          ></div>
        </div>

        <div
          v-show="
            !loading &&
            !isLoadingImage &&
            !checkedError &&
            Object.values(personalInfo).length > 0
          "
          class="flex justify-center"
        >
          <div>
            <div
              class="
                bg-white
                p-3
                shadow-lg
                rounded-lg
                md:w-[750px]
                lg:px-20
                px-5
                py-10
                md:px-10
                mt-6
                mb-5
              "
            >
              <div class="flex justify-center">
                <img
                  src="../../assets/complete-form.svg"
                  alt="img-check-email"
                  class="w-[325px]"
                  width="325"
                  height="325"
                  @load="onLoadImage"
                />
              </div>
              <div
                class="
                  container
                  p-4
                  mt-4
                  border
                  bg-[#E6F6EC]
                  border-[#008444]
                  rounded-lg
                  w-full
                "
              >
                <div class="container mx-auto">
                  <div class="grid md:grid-cols-12">
                    <div class="col-span-12">
                      <div class="flex">
                        <div class="mr-2">
                          <img
                            src="@/assets/icon.png"
                            alt="check icon"
                            class="hidden md:block md:w-[30px] md:h-[16px]"
                            width="30"
                            height="16"
                          />
                        </div>
                        <div class="text-[14px] font-[Lato]">
                          <p class="text-black font-semibold">Terima Kasih</p>
                          <p class="text-black">
                            Kami akan melakukan review terlebih dahulu dan
                            memberitahukan proses selanjutnya melalui Email.
                            Mohon cek secara berkala Inbox Email Anda.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex justify-center">
                <div class="mt-8">
                  <jds-button @click="backToTheHome"
                    >Kembali ke Beranda</jds-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-show="checkedError && Object.values(personalInfo).length === 0"
          class="h-[calc(100vh-310px)] flex justify-center items-center"
        >
          <NotFound title="Mohon maaf, halaman tidak ditemukan" />
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import NotFound from "@/components/NotFound";
export default {
  components: {
    NotFound,
  },
  data() {
    return {
      isLoadingImage: true,
    };
  },
  created() {
    this.$store.commit("setPersonalInfoId", this.$route.params.id);

    this.$store.dispatch("getPersonalInformation");
  },
  computed: {
    personalInfo() {
      return this.$store.getters.getPersonalInfo;
    },
    loading() {
      return this.$store.getters.getLoading;
    },
    checkedError() {
      return this.$store.getters.getError;
    },
  },
  methods: {
    backToTheHome() {
      this.$store.commit("setPage", 1);
      this.$router.push("/");
    },
    onLoadImage() {
      this.isLoadingImage = false;
    },
  },
};
</script>
  