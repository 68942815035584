<template name="component-name">
  <div
    class="
      transition-all
      w-full
      duration-300
      min-h-[calc(100vh-310px)]
      job-details
      bg-gray-100
    "
  >
    <div v-if="isLoading">
      <div class="h-[calc(100vh-310px)] flex justify-center items-center">
        <BaseLoading />
      </div>
    </div>
    <div
      v-else-if="!checkedError && Object.values(jobData).length > 0"
      class="container mx-auto p-4"
    >
      <div
        class="
          grid grid-row-2
          lg:gap-x-20
          md:divide-x-0
          sm:divide-x-0
          my-8
          place-content-center
        "
      >
        <div class="col-span-12 lg:mb-7 mb-5">
          <jds-button class="back-btn w-[119px]" @click="getBackToLandingPage">
            <div class="flex">
              <ChevronLeftIcon class="w-[14px] h-[14px] mt-[6px] mr-2" />
              Kembali
            </div>
          </jds-button>
        </div>
        <div class="col-span-12 lg:col-span-4">
          <div class="flex justify-center mb-5 lg:mb-0">
            <div class="shadow-xl rounded-lg">
              <img
                :src="jobData.role.image_url"
                class="rounded-lg"
                width="323"
                height="450"
                alt="no image"
              />
            </div>
          </div>
        </div>
        <div class="col-span-12 lg:col-span-8 lg:ml-5 xl:ml-0">
          <div class="lg:w-[500px] xl:w-[691px]">
            <div class="flex justify-start">
              <h1 class="text-4xl semi-bold mb-5">
                {{ jobData.role.name }}
              </h1>
            </div>
            <div class="flex justify-start">
              <div>
                <h6 class="font-semibold mb-5">Tentang Role ini</h6>
                <p class="text-[16px] font-normal pr-4">
                  {{ jobData.role.description }}
                </p>

                <h6 class="font-semibold mt-5">Apa yang Anda Kerjakan</h6>
                <div class="container mx-auto p-4">
                  <ul v-if="jobData.role.responsibilities.length > 0">
                    <li
                      v-for="(data, index) in jobData.role.responsibilities"
                      :key="index"
                    >
                      {{ data.description }}
                    </li>
                  </ul>
                  <div v-else>
                    <p>-</p>
                  </div>
                </div>

                <h6 class="font-semibold mt-5">Apa yang Anda Butuhkan</h6>
                <div class="container mx-auto p-4">
                  <ul v-if="jobData.role.skills.length > 0">
                    <li
                      v-for="(data, index) in jobData.role.skills"
                      :key="index"
                    >
                      {{ data.description }}
                    </li>
                  </ul>
                  <div v-else>
                    <p>-</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex justify-start mt-5 container">
              <jds-button class="apply-btn w-full" @click="goToTheApplyJobPage">
                Daftar Sekarang
              </jds-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="h-[calc(100vh-310px)] flex justify-center items-center">
      <NotFound title="Mohon maaf, lowongan yang anda cari tidak ditemukan" />
    </div>
  </div>
</template>

<script>
import { ChevronLeftIcon } from "@vue-hero-icons/outline";
import NotFound from "@/components/NotFound";
import BaseLoading from "@/components/Loading";

export default {
  name: "JobDetails",
  components: {
    ChevronLeftIcon,
    NotFound,
    BaseLoading,
  },
  data() {
    return {
      image: null,
    };
  },
  computed: {
    jobData() {
      return this.$store.getters.getJobDescription;
    },
    checkedError() {
      return this.$store.getters.getError;
    },
    isLoading() {
      return this.$store.getters.getLoading;
    },
  },
  mounted() {
    this.$store.commit("setSlug", this.$route.params.slug);
    this.$store.dispatch("getJobsDetails");
  },

  methods: {
    getBackToLandingPage() {
      this.$router.push("/");
    },
    goToTheApplyJobPage() {
      this.$router.push({
        name: "apply-job",
        params: { slug: this.$route.params.slug },
      });
    },
  },
};
</script>

<style lang="scss">
.job-details {
  font-family: "Inter", serif;
  .apply-btn {
    background-color: #069550;
    color: #fff;
    border-radius: 8px;
    box-shadow: inset 0px 0px 0px 1px #fff;
    max-height: 45px;
    font-size: 14px;
    font-weight: 700;
    font-family: "Lato", serif;
  }
  .back-btn {
    background-color: transparent;
    border: 1px solid #069550;
    color: #069550;
    font-size: 14px;
  }
  .back-btn:hover {
    background-color: transparent;
  }
  p,
  ul,
  li {
    color: #212121;
    font-style: "regular";
    font-weight: 400;
  }
  h6 {
    color: #212121;
    font-size: 16px;
    font-weight: bold;
  }
  h1 {
    font-family: "Lora", serif;
    font-weight: 700;
    font-size: 24px;
  }
}
</style>
