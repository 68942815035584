<template name="header-app">
  <nav
    class="
      backdrop-filter backdrop-blur-lg
      border-b border-gray-300
      text-white
      w-full
      transition-all
      duration-300
      bg-[#022B55]
      h-[250px]
    "
  >
    <div class="grid grid-cols-12">
      <div class="col-span-12 md:col-span-9">
        <div
          class="
            flex flex-wrap
            justify-between
            items-end
            max-w-screen-xl
            container
            mx-auto
            pl-6
            lg:pl-[108px]
            xl:pl-[158px]
            pr-6
            h-[250px]
            py-11
          "
        >
          <router-link to="/" class="flex items-center">
            <img
              src="@/assets/jds-main-logo.svg"
              class="mr-3 h-[77px]"
              alt="JDS Logo"
              loading="lazy"
            />
            <span class="z-10">
              <div>
                <h1
                  class="
                    self-center
                    text-xl
                    font-bold
                    whitespace-nowrap
                    mb-[10px]
                    font-[Inter]
                  "
                >
                  Jabar Digital Service
                </h1>
                <p
                  class="
                    self-center
                    lg:max-w-sm
                    sm:text-sm
                    md:text-xl
                    lg:text-[28px]
                    md:whitespace-nowrap
                    font-[Lora]
                    font-bold
                  "
                >
                  Memberikan Dampak untuk Jawa Barat dan Indonesia
                </p>
              </div>
            </span>
          </router-link>
        </div>
      </div>
      <div class="col-span-12 md:col-span-3">
        <div
          class="
            hidden
            md:flex
            justify-between
            items-end
            max-w-screen-xl
            container
            mx-auto
            pl-6
            pr-6
            h-[249px]
          "
        >
          <img src="@/assets/header-watermark.svg" alt="header" />
        </div>
      </div>
    </div>
  </nav>
</template>

<script>

export default {
  name: "HeaderApp",
  data() {
    return {
      isDark: false,
    };
  },
  mounted() {
    document.querySelector("html").classList.add(localStorage.getItem("theme"));
    const localTheme = localStorage.getItem("theme");

    if (localTheme == "dark") {
      this.isDark = true;
    } else {
      this.isDark = false;
    }
  },
  methods: {
    selectTheme(indexing) {
      localStorage.setItem("theme", indexing);
      const html = document.querySelector("html");
      html.classList.add(localStorage.getItem("theme"));
      if (indexing == "dark") {
        html.classList.remove("light");
      } else {
        html.classList.remove("dark");
      }
    },

    onChangeToggle(e) {
      let checked = e.target.checked;

      if (checked) {
        this.isDark = true;
        this.selectTheme("dark");
      } else {
        this.isDark = false;
        this.selectTheme("light");
      }
    },
  },
};
</script>
