var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-gray-100 transition-all w-full duration-300 min-h-[calc(100vh-310px)]"},[_c('div',{staticClass:"mx-auto p-4 w-full"},[_c('div',{staticClass:"container mx-auto pb-4 pt-4 px-14"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading && _vm.isLoadingImage),expression:"loading && isLoadingImage"}],staticClass:"h-[calc(100vh-310px)] flex justify-center items-center"},[_c('div',{staticClass:"w-16 h-16 border-4 border-[#069550] border-solid rounded-full animate-spin",staticStyle:{"border-top-color":"transparent"}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
          !_vm.loading &&
          !_vm.isLoadingImage &&
          !_vm.checkedError &&
          Object.values(_vm.tableData).length > 0
        ),expression:"\n          !loading &&\n          !isLoadingImage &&\n          !checkedError &&\n          Object.values(tableData).length > 0\n        "}],staticClass:"flex justify-center"},[_c('div',[_c('div',{staticClass:"bg-white p-3 shadow-lg rounded-lg md:w-[750px] lg:px-20 px-5 py-10 md:px-10 mt-6 mb-5"},[_c('div',{staticClass:"flex justify-center"},[_c('img',{staticClass:"w-[325px]",attrs:{"src":require("../../assets/check-email.svg"),"alt":"img-check-email","width":"325","height":"325"},on:{"load":_vm.onLoadImage}})]),_vm._m(0),_c('div',{staticClass:"flex justify-center"},[_c('div',{staticClass:"mt-8"},[_c('jds-button',{on:{"click":_vm.backToTheHome}},[_vm._v("Kembali ke Beranda")])],1)])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkedError && Object.values(_vm.tableData).length === 0),expression:"checkedError && Object.values(tableData).length === 0"}],staticClass:"h-[calc(100vh-310px)] flex justify-center items-center"},[_c('NotFound',{attrs:{"title":"Mohon maaf, halaman tidak ditemukan"}})],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container p-4 mt-4 border bg-[#E6F6EC] border-[#008444] rounded-lg w-full"},[_c('div',{staticClass:"container mx-auto"},[_c('div',{staticClass:"grid md:grid-cols-12"},[_c('div',{staticClass:"col-span-12"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"mr-2"},[_c('img',{staticClass:"hidden md:block md:w-[30px] md:h-[16px]",attrs:{"src":require("@/assets/icon.png"),"alt":"check icon","width":"30","height":"16"}})]),_c('div',{staticClass:"text-[14px] font-[Lato]"},[_c('p',{staticClass:"text-black font-semibold"},[_vm._v(" Silakan Cek Email Anda ")]),_c('p',{staticClass:"text-black"},[_vm._v(" Terima kasih sudah mengisi data pendaftaran, data Anda sudah kami simpan. Silahkan cek email Anda secara berkala untuk melanjutkan proses pendaftaran. ")])])])])])])])
}]

export { render, staticRenderFns }