<template name="component-name">
  <div class="table-landing-page">
    <div v-for="(data, index) in tableData" :key="index">
      <div
        class="
          bg-white
          lg:w-[932px]
          md:w-[632px]
          w-[100%]
          mt-2
          mb-2
          rounded-[12px]
        "
      >
        <div class="container mx-auto p-3 flex justify-between shadow-lg">
          <h6
            class="
              lg:text-[14px]
              sm:text-sm
              name-job
              text-[#022B55]
              my-auto
              items-center
              font-[600]
            "
          >
            {{ data.role.name }}
          </h6>
          <jds-button
            class="ml-5 btn-text md:ml-0"
            @click="goToTheFormPage(data.slug)"
          >
            Bergabung
          </jds-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TableApp",
  props: {
    tableData: {
      type: Array,
      required: true,
    },
  },
  methods: {
    goToTheFormPage(slug) {
      this.$router.push({
        name: "job-details",
        params: {
          slug: slug,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.table-landing-page {
  h6 {
    font-family: "Inter", sans-serif;
  }
  .btn-text {
    font-family: "Lato", sans-serif;

    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
  }
  .jds-button {
    background-color: #069550;
    color: #fff;
    box-shadow: inset 0px 0px 0px 1px #fff;
    max-height: 45px;
    width: 100px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 8px;
  }
  .name-job {
    max-width: 400px;
  }
}
</style>
